import { Box, createTheme, Divider, Grid, Paper, ThemeProvider, Typography } from '@mui/material'
import {
  MaterialReactTable,
  MRT_Row,
  type MRT_ColumnDef,
  type MRT_ExpandedState,
  type MRT_TableOptions,
} from 'material-react-table'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { themeOptions } from '../../themes/main'
import { FieldDefinition, FormDefinition } from '../../types/apiModels'
import { formatNumber } from '../../utils/formatNumber'
import { ColumnOption, MetricEventRow } from './dataTableHelpers'

type ExpandableDataTableProps = {
  formDefinition: FormDefinition
  data: MetricEventRow[]
  columns: ColumnOption[]
  excludedDataKeys?: string[]
  customOptions?: Partial<MRT_TableOptions<MetricEventRow>>
  isAllExpanded?: boolean
  title?: string
}

const tableTheme = createTheme({
  ...themeOptions,
  components: {
    ...themeOptions.components,
  },
})

export const ExpandableDataTable: FC<ExpandableDataTableProps> = ({
  formDefinition,
  data,
  columns,
  excludedDataKeys,
  customOptions,
  isAllExpanded = false,
  title,
}) => {
  const [expanded, setExpanded] = useState<MRT_ExpandedState>({})

  useEffect(() => {
    if (isAllExpanded && data.length) {
      const expandedState: MRT_ExpandedState = {}
      data.forEach((_, index) => {
        expandedState[index] = true
      })
      setExpanded(expandedState)
    }
  }, [data, isAllExpanded])

  const tableColumns = useMemo<MRT_ColumnDef<MetricEventRow>[]>(() => {
    return columns.map(column => ({
      accessorKey: column.name,
      header: column.label,
      enableColumnFilter: column.options?.filter ?? false,
      enableSorting: column.options?.sort ?? false,
      Cell: column.options?.customBodyRender
        ? ({ cell }): ReactNode => column.options.customBodyRender?.(cell.getValue())
        : undefined,
      ...(column.options?.display === false ? { enableHiding: true, hidden: true } : {}),
    }))
  }, [columns])

  const renderDetailPanel = ({ row }: { row: MRT_Row<MetricEventRow> }): ReactNode => {
    const visibleColumnNames = columns.map(column => column.name)

    const expandableFields = formDefinition.fields.filter(
      (field: FieldDefinition) =>
        field.name &&
        !visibleColumnNames.includes(field.name) &&
        !excludedDataKeys?.includes(field.name),
    )

    const fieldGroups = expandableFields.reduce(
      (acc, field: FieldDefinition) => {
        if (field.component_type === 'FieldArray') {
          acc.fieldArrayFields.push(field)
        } else {
          acc.nonFieldArrayFields.push(field)
        }
        return acc
      },
      { fieldArrayFields: [] as FieldDefinition[], nonFieldArrayFields: [] as FieldDefinition[] },
    )

    const rowData = row.original

    return (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {fieldGroups.nonFieldArrayFields.map((field: FieldDefinition) => (
            <Grid key={`${field.label}-${row.id}`} item xs={6}>
              <Typography component="span">
                <b>{field.label}:</b> {formatNumber(rowData[field.name]) ?? 'N/A'}
              </Typography>
            </Grid>
          ))}
        </Grid>
        {fieldGroups.fieldArrayFields.map((field: FieldDefinition) => (
          <Box key={field.name}>
            <Divider sx={{ margin: '8px 0' }} />
            <Typography component="span">
              <b>{field.label}:</b> {(rowData[field.name] as ReactNode) ?? 'N/A'}
            </Typography>
          </Box>
        ))}
      </Box>
    )
  }

  // Enhanced global filter function that searches all properties of the row
  const enhancedGlobalFilterFn = (
    row: MRT_Row<MetricEventRow>,
    columnIds: string[],
    filterValue: string,
  ): boolean => {
    if (!filterValue) return true

    const searchTerm = String(filterValue).toLowerCase()

    // First check visible columns (standard behavior)
    if (Array.isArray(columnIds)) {
      const visibleMatch = columnIds.some(columnId => {
        const value = row.getValue(columnId)
        if (value === null || value === undefined) return false

        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchTerm)
        }
        if (typeof value === 'number') {
          return value.toString().includes(searchTerm)
        }
        if (value && typeof value === 'object' && 'props' in value) {
          // Handle React components - safely stringify
          try {
            const stringified = JSON.stringify(value.props)
            return stringified && stringified.toLowerCase().includes(searchTerm)
          } catch (e) {
            return false
          }
        }
        return false
      })

      if (visibleMatch) return true
    }

    // Then check all other properties in the original data
    const rowData = row.original

    // Search in all properties including nested ones
    const searchInObject = (obj: any): boolean => {
      if (obj === null || obj === undefined) return false

      if (typeof obj === 'string') {
        return obj.toLowerCase().includes(searchTerm)
      }

      if (typeof obj === 'number') {
        return obj.toString().includes(searchTerm)
      }

      if (Array.isArray(obj)) {
        return obj.some(item => searchInObject(item))
      }

      if (typeof obj === 'object') {
        // Safely handle React elements or other complex objects
        try {
          return Object.values(obj).some(value => searchInObject(value))
        } catch (e) {
          return false
        }
      }

      return false
    }

    return searchInObject(rowData)
  }

  const { state, ...rest } = customOptions ?? {}

  const defaultOptions: Partial<MRT_TableOptions<MetricEventRow>> = {
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enablePagination: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableHiding: false,
    filterFns: {
      enhancedFilter: enhancedGlobalFilterFn as any,
    },
    globalFilterFn: 'enhancedFilter',
    muiTableHeadRowProps: {
      sx: {
        background: '#e3e9ef',
        fontWeight: 'bold',
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: { border: '1px solid #e0e0e0' },
    },
    renderDetailPanel,
    muiDetailPanelProps: {
      sx: {
        background: '#f9f9f9',
      },
    },
    state: {
      expanded,
      ...state,
    },
    onExpandedChange: setExpanded,
    enableExpanding: true,
  }

  const tableOptions: Partial<MRT_TableOptions<MetricEventRow>> = {
    ...defaultOptions,
    ...rest,
  }

  return (
    <ThemeProvider theme={tableTheme}>
      <Paper elevation={0}>
        {title && (
          <Typography sx={{ p: 2 }} variant="h6">
            {title}
          </Typography>
        )}
        <MaterialReactTable columns={tableColumns} data={data} {...tableOptions} />
      </Paper>
    </ThemeProvider>
  )
}
