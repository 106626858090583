import { useState, useEffect, useContext, useCallback } from 'react'
import * as turf from '@turf/turf'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useAuth0 } from '@auth0/auth0-react'
import AppContext from '../../context/appContext'
import EventPanel from './EventPanel'
import EventMap from './EventMap'
import EventsList from './EventsList'
import { GeoJSON } from '../../utils/GeoJSON/index'
import { ruutsApi } from '../../services/ruutsApi/index'
import endpoints from '../../services/ruutsApi/endpoints'
import CreateEventButton from './CreateEventButton'
import CreateEventDialog from './CreateEventDialog'
import { useUserRole } from '../../hooks/useUserRole'
import { useProcessLoadingWithConfirmation } from '../../utils/Loading/useProcessLoadingWithConfirmation'

const EventPlanning = () => {
  const { currentFarm, setLoadingModalConfig } = useContext(AppContext)
  const { getAccessTokenSilently } = useAuth0()
  const [_samplingAreas, setSamplingAreas] = useState()
  const [samplingAreasFeatureCollection, setSamplingAreasFeatureCollection] = useState()
  const [farm, setFarm] = useState()
  const [perimeter, setPerimeter] = useState()
  const [monitoringWorkflows, setMonitoringWorkflows] = useState()
  const [selectedSamplingAreas, setSelectedSamplingAreas] = useState([])
  const [monitoringSites, setMonitoringSites] = useState({})
  const [selectedMonitoringSites, setSelectedMonitoringSites] = useState([])
  const [mapWidth] = useState(window.innerWidth / 2)
  const [mapHeight] = useState(window.innerHeight)
  const [events, setEvents] = useState([])
  const [createEventView, setCreateEventView] = useState(false)
  const { isViewOnly, isAdmin } = useUserRole()
  const { processLoadingWithConfirmation } = useProcessLoadingWithConfirmation()

  const [createEventWithGpxView, setCreateEventWithGpxView] = useState(false)
  const [createEventOptions, setCreateEventOptions] = useState([
    {
      label: 'Crear evento',
      action: () => setCreateEventView(true),
    },
  ])

  const getFarmData = useCallback(
    async function getFarmData() {
      const token = await getAccessTokenSilently()
      const currentSamplingAreas = await ruutsApi.samplingAreas.getCroppedByFarm({
        farmId: currentFarm.id,
        token,
      })
      const currentSites = await ruutsApi.getApiData(
        endpoints.sites,
        { farmId: currentFarm.id },
        token,
      )
      const workflows = await ruutsApi.getApiData(endpoints.workflows, null, token)

      const samplingAreasFeatures = turf.featureCollection([])

      currentSamplingAreas.forEach(samplingArea => {
        const feature = samplingArea.toGeoJSON
        feature.properties.area = GeoJSON.hectareArea(feature).toFixed(2)
        samplingAreasFeatures.features.push(feature)
      })

      let monitoringEvents = await ruutsApi.getApiData(
        endpoints.events,
        { farmId: currentFarm.id },
        token,
      )
      monitoringEvents = monitoringEvents.map(monitoringEvent => {
        const monitoringEventSamplingAreas = currentSamplingAreas?.filter(samplingArea =>
          monitoringEvent.samplingAreas?.includes(samplingArea.id),
        )
        const monitoringEventMonitoringSites = currentSites?.filter(monitoringSite =>
          monitoringEvent.monitoringSitesIds?.includes(monitoringSite.id),
        )
        return {
          ...monitoringEvent,
          samplingAreas: monitoringEventSamplingAreas,
          monitoringSites: monitoringEventMonitoringSites,
        }
      })

      setEvents(monitoringEvents)
      setMonitoringWorkflows(workflows)
      setFarm(currentFarm)
      setPerimeter(currentFarm.toGeoJSON)
      setSamplingAreas(currentSamplingAreas)
      setSamplingAreasFeatureCollection(samplingAreasFeatures)

      if (currentSites) {
        const sitesObj = {}
        currentSamplingAreas.forEach(area => {
          sitesObj[area.name] = currentSites.map(site => {
            if (site.samplingAreaId === area.id) {
              const feature = turf.point([site.location[1], site.location[0]])
              feature.properties.name = site.name
              feature.properties.id = site.id
              feature.properties.strata = area.name
              return feature
            }
            return undefined
          })

          sitesObj[area.name] = sitesObj[area.name].filter(site => {
            return site !== undefined
          })
        })

        setMonitoringSites(sitesObj)
      }
    },
    [currentFarm, getAccessTokenSilently],
  )

  const handleDeleteEvent = async eventId => {
    await processLoadingWithConfirmation({
      confirmationTitle: 'Confirmar eliminación de evento',
      confirmationMessage: '¿Estás seguro que deseas eliminar el evento?',
      loadingMessage: 'Eliminando evento...',
      errorMessage: 'Error al eliminar el evento',
      getToken: getAccessTokenSilently,
      setLoadingModalConfig,
      successfulMessage: 'Evento eliminado',
      doAction: async ({ token, dryRun }) => {
        if (dryRun) return
        await ruutsApi.deleteApiData(`${endpoints.events}/${eventId}`, null, token)
        await getFarmData()
      },
    })
  }

  useEffect(() => {
    getFarmData()
  }, [getFarmData])

  useEffect(() => {
    if (isAdmin) {
      setCreateEventOptions([
        {
          label: 'Crear evento',
          action: () => setCreateEventView(true),
        },
        {
          label: 'Crear desde archivo',
          action: () => setCreateEventWithGpxView(true),
        },
      ])
    } else {
      setCreateEventOptions([
        {
          label: 'Crear evento',
          action: () => setCreateEventView(true),
        },
      ])
    }
  }, [isAdmin])

  return (
    <Box>
      <Box sx={{ padding: '10px', textAlign: 'right' }}>
        {!createEventView && createEventOptions && createEventOptions.length > 0 && (
          <CreateEventButton disabled={isViewOnly} options={createEventOptions} />
        )}
      </Box>
      {!createEventView && (
        <EventsList
          events={events}
          handleDeleteEvent={handleDeleteEvent}
          monitoringWorkflows={monitoringWorkflows}
        />
      )}
      {createEventView && (
        <Grid container>
          <Grid item xs={6}>
            <EventPanel
              farm={farm}
              monitoringSites={monitoringSites}
              monitoringWorkflows={monitoringWorkflows}
              samplingAreas={samplingAreasFeatureCollection}
              selectedMonitoringSites={selectedMonitoringSites}
              selectedSamplingAreas={selectedSamplingAreas}
              setCreateEventView={setCreateEventView}
              setSelectedMonitoringSites={setSelectedMonitoringSites}
              setSelectedSamplingAreas={setSelectedSamplingAreas}
            />
          </Grid>
          <Grid item xs={6}>
            <EventMap
              mapHeight={mapHeight}
              mapWidth={mapWidth}
              monitoringSites={monitoringSites}
              perimeter={perimeter}
              samplingAreas={samplingAreasFeatureCollection}
              selectedMonitoringSites={selectedMonitoringSites}
              selectedSamplingAreas={selectedSamplingAreas}
            />
          </Grid>
        </Grid>
      )}
      <CreateEventDialog
        getFarmData={getFarmData}
        handleClose={() => setCreateEventWithGpxView(false)}
        open={createEventWithGpxView}
      />
    </Box>
  )
}

export default EventPlanning
